import React from 'react'
import { graphql} from 'gatsby'
import SEO from "../components/seo"
import Layout from '../components/layout'
import ArtistNav from '../components/artistNavBar'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import './artist-profile.css'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import LightGallery from 'lightgallery/react';
import lgZoom from 'lightgallery/plugins/zoom';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';


class ArtistProfileTemplate extends React.Component {

renderImages = (workPhotos) =>{
    if (workPhotos != null){
      return workPhotos.map((work, index) => 
          { if(work.photo != null)         
             {       
               if(work.photo.gatsbyImageData != null)
                  {
                    return <div key={work.photo.id}
                              className={work.photo.gatsbyImageData.height>work.photo.gatsbyImageData.width? "image-description-cont-vertical":'image-description-cont'} >
                                <a href={getImage(work.photo).images.fallback.src}>
                                  <GatsbyImage
                                    className="artist-profile-images"  
                                    image={getImage(work.photo)}
                                    alt={work.photo.description} 
                                    loading= "lazy" 
                                  />
                                </a>
                                <div className='artist-profile-caption'>{work.artworkInformation === null? "":renderRichText(work.artworkInformation)}</div>
                            </div>
                  }
                }
            }
                )
    }
    else 
    {
      return <h1>Images coming soon</h1>
    }
  }

  render() {
    const {slug, name, workPhotos} = this.props.data.contentfulArtist

    return (
      <Layout location={this.props.location}>

        <SEO 
          title={`${this.props.data.contentfulArtist.name}`}
          description={`Selected artworks by artist ${this.props.data.contentfulArtist.name}`}
        />

        <LightGallery
                selector=".artist-images a"
                download={false}
                counter={false}
                plugins={[lgZoom]}
              >

        <div style={{ background: '#fff' }}>
          <h1 className="artistHeader">{name}</h1>
            <div className="nav-artist-cont"><ArtistNav class="x"slug={slug}/></div>
            <div className="artist-images">{this.renderImages(workPhotos)}</div>
        </div>

        </LightGallery>

      </Layout>
    )
  }
}

export default ArtistProfileTemplate

export const pageQuery = graphql`
query artistAndArtisAndArtistAndArtis($slug: String!) {
  contentfulArtist(slug: {eq: $slug}) {
    slug
    name
    workPhotos {
      title
      year
      size
      medium
      photo {
        description
        id
        gatsbyImageData(
           quality: 70 
            width: 2000
           ) 
      }
      artworkInformation {
        raw
      }
    }
  }
}
`